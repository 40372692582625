<script setup>
import { Search, Refresh } from "@element-plus/icons-vue";
import { computed, reactive, ref, watch, useSlots } from "vue";
import SearchBarFields from "./SearchBarFields.vue";

const CSSgap = 12;

/**
 * @typedef {Object} props
 *
 * @property {Array<config>} columnConfig - 列配置数组
 * @typedef {object} config
 * @property {string} label - 标题
 * @property {string} key - 键
 * @property {string} default - 默认值
 * @property {string} type - 类型
 * @property {boolean} external - 外置字段
 *
 * type == 'suggestInput' 才有
 * @property {string} valueKey - 候选项绑定的key, 默认(name)
 * @property {function} search - 搜索的触发的函数(queryString: string, callback: callbackfn)
 * @property {function} select - 选择的时候触发的函数(item: typeof modelValue | any)
 *
 */
const props = defineProps({
  optionData: {
    type: Object,
    default: {},
  },
  config: {
    type: Array,
    default: [{ label: "关键字", key: "keyword", external: true }],
  },
  external: Boolean,
  column: {
    type: [Number, String],
    default: 5,
  }
});

const slots = useSlots();

const res = defineModel("res", { type: Object, default: () => reactive({}) });

const fields = computed(() => props.config)

const externalFields = computed(() =>
  fields.value.filter((item) => {
    if (item.default !== undefined) res.value[item.key] = item.default;
    return item.external;
  })
);
const emits = defineEmits(["search"]);

const showDetail = defineModel('showDetail', { default: false });

if (props.external) showDetail.value = true

// methods
function search() {
  emits("search", res.value);
}
// methods

defineExpose({
  search,
});
</script>

<template>
  <div class="thePageSearchBox">
    <div class="flex _aiC _fwW gapM"
      style="row-gap: 16px;">
      <template v-if="!props.external">
        <div class="flex _aiC _fwW gapM"
          v-if="externalFields.length"
          v-show="!showDetail">
          <div v-for="cf in externalFields"
            class="flex _aiC">
            <div style="word-break: keep-all"
              :title="cf.label">{{ cf.label }}：</div>

            <div class="flex"
              :style="`width: ${{
                switch: 'auto',
                datePicker: cf?.config?.type == 'daterange' ? '252px' : null
              }[cf.type] || (slots[cf.key] && 'auto') || '168px'}`">
              <template v-if="slots[cf.key]">
                <slot :name="cf.key"
                  v-bind="{ res, external: true }" />
              </template>
              <SearchBarFields v-else
                class="f1"
                v-model:value="res[cf.key]"
                :data="props.optionData[cf.key]"
                :cf="cf" />
            </div>
          </div>
        </div>
        <div v-show="!showDetail">
          <el-button type="primary"
            :icon="externalFields.length ? Search : Refresh"
            @click="search">
            {{ externalFields.length ? "搜索" : "刷新" }}
          </el-button>
        </div>
        <el-button v-if="externalFields.length != fields.length"
          type="primary"
          plain
          @click="showDetail = !showDetail">
          {{ showDetail ? "收起" : "展开" }}详细搜索
        </el-button>
      </template>
      <div class="ml_A"> </div>
      <slot name="rightTop"></slot>
    </div>
    <div class="searchDetailBox"
      :style="showDetail && !props.external && externalFields.length != fields.length && 'border: 1px solid #bfbfbf'">
      <template v-if="externalFields.length != fields.length">
        <div class="o_A bc_W flex _fwW gapM"
          :class="showDetail && !props.external && ['p_L']"
          :style="{
            height: showDetail ? 'auto' : 0,
            rowGap: CSSgap + 'px',
            justifyContent: 'space-between'
          }">
          <div v-for="cf in fields"
            class="flex _aiC"
            :style="`width: calc(${100 / (props.column / (cf.wide || 1))}% - ${(CSSgap * (props.column - 1)) / props.column / (cf.wide || 1)}px)`">
            <div class="ta_R overlineByOne"
              style="min-width: 5em;"
              :title="cf.label">{{ cf.label }}：</div>

            <div class="f1 flex">
              <template v-if="slots[cf.key]">
                <slot :name="cf.key"
                  v-bind="{ res, external: false }" />
              </template>
              <SearchBarFields v-else
                class="f1"
                v-model:value="res[cf.key]"
                :data="props.optionData[cf.key]"
                :cf="cf" />
            </div>
          </div>
          <div class="ml_A">
            <el-button type="primary"
              :icon="Search"
              @click="search">
              搜索
            </el-button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.thePageSearchBox {
  font-size: 0.9em;
  gap: 8px;

  .searchDetailBox {
    box-sizing: border-box;
    margin: 8px 0;
  }
}
</style>
