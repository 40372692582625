const test = false;
import { defineStore } from "pinia";
import { reactive, watch, shallowReactive, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import routeCache from "../router/routeCache";
import $$ from "@/tools";
import roleIds from "../router/roleIds";
import { ElMessage, ElMessageBox } from "element-plus";

export const useUserStore = defineStore(
  "user",
  () => {
    const router = useRouter();
    const route = useRoute();
    const initState = () => ({
      login: false,
      menu: [],
      allUser: [],
      // TODO 仓库应该移到全局仓库去
      allStore: [],
      defaultStore: "",
      allMenu: [],
      nowRoute: "",
      token: "",
      loginInfo: {
        // isAdmin:
        // serviceObjectList: [
        //   { id: 2, name: "一味乐2" },
        //   { id: 1, name: "一味乐" },
        // ],
        // userAccount: "13923111111",
        // userName: "ppp",
        // serviceObjectId: 2,
        // serviceObjectName: "一味乐2",
        // token:
        //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiJTeXN0ZW1fVXNlcl9JZF80IiwidXNlckFjY291bnQiOjIxNDc0ODM2NDcsImxvZ2luVGltZSI6MTcyMzQ3MTAyNTM1NH0.IpPLdC24iDpzVp2cYsChxWGdqG8ZyM04JAGs6gjYEck",
      },
    });

    const state = reactive(initState());

    watch(
      () => route.path,
      (n) => {
        state.nowRoute = n;
      }
    );

    function visibilitychange() {
      // console.log("页面可见性状态改变为: ", document.visibilityState);
      // // 在这里可以根据页面的可见性状态执行相应的操作
      // if (document.visibilityState === "hidden") {
      //   // 页面不可见时的逻辑
      //   console.log("页面不可见");
      // } else if (document.visibilityState === "visible") {
      //   // 页面可见时的逻辑
      //   console.log("页面可见");
      // }
    }
    watch(
      () => state.login,
      (n) => {
        if (n) {
          document.addEventListener("visibilitychange", visibilitychange);
        } else {
          document.removeEventListener("visibilitychange", visibilitychange);
        }
      },
      { immediate: true }
    );

    const actions = {
      checkLoginInfo(info) {
        if (!info?.moduleIds?.length) {
          ElMessage.warning("您在该主体下无权查看任何页面，请联系管理员");
          return true;
        }
      },
      getAllUser() {
        return new Promise((resolve, reject) => {
          const successCB = (res) => {
            state.allUser = res.data;
            resolve();
          };
          $$.request({
            url: "system/getAdmin",
            method: "get",
            params: {
              enable: true,
              simpleFlag: true,
            },
          })
            .then(successCB)
            .catch(reject);
        });
      },
      getAllStore() {
        return new Promise((resolve, reject) => {
          try {
            $$.constant.suggest.warehouseList(undefined, (res) => {
              state.allStore = res;
              state.defaultStore = state.allStore.find(
                (item) => item.name == "内部仓"
              )?.id;
              resolve();
            });
          } catch (err) {
            reject();
          }
        });
      },
      getUserNameById(id) {
        return state.allUser.find((item) => item.objectId == id).name;
      },
      goFirstPage() {
        const getFirstPath = (data) => {
          if (Array.isArray(data)) {
            if (data.length > 0) {
              const firstItem = data[0];
              if (firstItem.path) {
                return firstItem.path;
              } else if (firstItem.children) {
                return getFirstPath(firstItem.children);
              }
            }
          } else if (typeof data === "object") {
            if (data.path) {
              return data.path;
            } else if (data.children) {
              return getFirstPath(data.children);
            }
          }

          return null;
        };
        const path = getFirstPath(state.menu);

        if (path) {
          router.replace({ path });
          state.login = true;
        } else {
          actions.logout();
        }
      },
      login() {
        const successCB = (res) => {
          const handleModule = (target) => {
            let array = [];
            target.forEach((item) => {
              // needChe 加一个占料管理
              // if (item.id == 500)
              //   item.childrenList.push({ id: 505, pId: 500, name: "报销管理" });
              // needChe

              // if (state.loginInfo.moduleIds.includes(item.id)) {
              if (item.isParent) {
                array.push({
                  id: item.id,
                  title: item.name,
                  children: handleModule(item.childrenList),
                });
              } else {
                array.push({
                  id: item.id,
                  title: item.name,
                  path: roleIds.page[item.id],
                });
              }
              // }
            });

            return array; //.filter((item) => item.path || item?.children?.length);
          };
          state.allMenu = res.data.moduleArray;
          state.menu = handleModule(res.data.moduleArray);
          // state.menu = [
          //   {
          //     title: "测试组",
          //     children: [
          //       { title: "测试", path: "/Test/Test__" },
          //       {
          //         title: "测试_",
          //         children: [
          //           { title: "测试测试测试", path: "/Test/Test___" },
          //           { title: "测试测试测试", path: "/Test/Test____" },
          //         ],
          //       },
          //     ],
          //   },
          //   { title: "测试", path: "/Test/Test" },
          //   { title: "测试_", path: "/Test/Test_" },
          // ];

          actions.goFirstPage();
        };
        $$.request({
          url: "system/getModule",
          method: "get",
        }).then(successCB);
      },
      logout() {
        Object.assign(state, initState());
        routeCache.clear();
        router.replace({ name: "Login" });
      },
    };

    return { state, actions };
  },
  {
    persist: {
      afterRestore: (ctx) => {
        if (location.hash == "#/" && ctx.store.state.login) {
          ctx.store.actions.goFirstPage();
        }
      },
    },
  }
);

export const useGlobalStore = defineStore("global", () => {
  const state = reactive({
    mainInvitationData: {},
    mainInvitationShow: false,
    mainInvitationChoice: null,

    materialChoiceRes: {},
    materialChoiceShow: false,
    materialChoiceCB: null,

    procurementDetailRes: {},
    procurementDetailShow: false,
    procurementDetailType: "",
    procurementDetailCB: null,

    allSupplierType: [],
    allMaterialType: [],
  });

  const store = {
    someClient: [],
    someSupplier: [],
  };

  const actions = {
    showMaterialChoice(CB, choiceRows = []) {
      state.materialChoiceShow = true;
      state.materialChoiceCB = CB;
      state.materialChoiceRes = choiceRows.reduce((pre, item) => {
        pre[item.id] = item;
        return pre;
      }, {});
    },
    initProcurementDetail(CB, data = {}) {
      state.procurementDetailCB = CB;
      state.procurementDetailRes = data;
      /* 
      "id": 4,
      "code": "CGSQ2024111903332",
      "purchaseDate": "2024-11-29",
      "earliestTargetDate": "2024-11-30",
      "remark": "测试",
      "status": 3,
      "items": [
        {
          "id": 1,
          "materialId": 1,
          "name": "牛皮3",
          "code": "NP",
          "typeName": "成品",
          "bomUnit": "克",
          "purchaseUnit": "斤",
          "bomUseQuantity": "20",
          "purchaseQuantity": "10"
        }
      ] 
      */
    },
    // 打开任务列表
    showTask() {
      /* 在业务组件内初始化 */
    },

    getAllSupplierType() {
      return new Promise((resolve, reject) => {
        try {
          const successCB = (res) => {
            state.allSupplierType = res.data;
            resolve();
          };
          $$.request({
            url: "data/getSupplierType",
            method: "get",
          }).then(successCB);
        } catch (err) {
          reject();
        }
      });
    },
    getAllMaterialType() {
      return new Promise((resolve, reject) => {
        try {
          const successCB = (res) => {
            state.allMaterialType = res.data;
            resolve();
          };
          $$.request({
            url: "data/getMaterialType?simpleFlag=true",
            method: "get",
          }).then(successCB);
        } catch (err) {
          reject();
        }
      });
    },
    getSomeClient(CB) {
      if (store.someClient.length) CB(store.someClient);
      else
        $$.constant.suggest.client({ pageSize: 25 }, (res) =>
          CB((store.someClient = res))
        );
    },
    getSomeSupplier(CB) {
      if (store.someSupplier.length) CB(store.someSupplier);
      else
        $$.constant.suggest.supplier({ pageSize: 25 }, (res) =>
          CB((store.someSupplier = res))
        );
    },
  };

  return { state, actions };
});

export const useComplexForm = defineStore(
  "complexForm",
  () => {
    const state = reactive({
      type: "",
      actionName: "",
      actionType: "",
      data: "",
    });
    const actionRelation = { add: "新增", edit: "编辑" };
    const pagePath = "/ComplexForm";
    const isLive = computed(
      () =>
        routeCache.state.store[pagePath] &&
        routeCache.state.store[pagePath].meta.title
    );
    const goPage = () => {
      routeCache.change(pagePath);
    };
    const actions = {
      ask(goCB, justGo) {
        if (isLive.value && !justGo)
          ElMessageBox.confirm(
            `当前正在${isLive.value}，请确认表单是否保存`,
            "提示",
            {
              cancelButtonText: "前往确认",
              confirmButtonText: "已确认，继续",
              distinguishCancelAndClose: true,
            }
          )
            .then(goCB)
            .catch((res) => {
              if (res == "cancel") goPage();
            });
        else goCB();
      },
      go(type, data) {
        const str = type.split("_");
        const rush = () => {
          state.type = str[0];
          state.actionName = str[1];
          state.actionType = str[2];
          state.data = data || {};
          goPage();
        };
        actions.ask(rush);
      },
      close() {
        routeCache.close(pagePath);
      },
      setTitle(val = "复杂表单") {
        routeCache.state.store[pagePath].meta.title =
          (actionRelation[state.actionType] || "") + val;
      },
    };

    return { state, actions };
  },
  {
    persist: true,
  }
);
