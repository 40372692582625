<script setup>
const props = defineProps({
    data: Object,
    value: [String, Number, Boolean]
})
</script>

<template>
    <!-- <FieldRelation :value="scope.row.status"
          :data="$$.constant.optionRelation.saleOrderDetailsStatus" /> -->
    <span v-if="props.value + 1"
        :style="{ color: props.data[props.value]?.color }">{{
            props.data[props.value]?.name
        }}</span>
</template>

<style scoped lang="scss"></style>
