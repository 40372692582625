import Table from "../Table.vue";
import AddAndEditDialog from "../AddAndEditDialog.vue";
import SearchBar from "../SearchBar.vue";
import ImportAndExport from "../ImportAndExport.vue";
import FieldRelation from "../FieldRelation.vue";

const components = [
  Table,
  AddAndEditDialog,
  SearchBar,
  ImportAndExport,
  FieldRelation,
];

export default {
  install: (app) => {
    components.forEach((component) => {
      // 在app上进行扩展，app提供 component directive 函数
      // 如果要挂载原型 app.config.globalProperties 方式
      app.component(component.name || component.__name, component);
    });
  },
};
