const routes = [
  {
    path: "/",
    redirect: "/Login",
  },
  {
    path: "/Login",
    name: "Login",
    meta: { title: "登录" },
  },
  {
    path: "/_",
    name: "_",
    meta: { name: " " },
    component: () => import("../pages/_.vue"),
  },
  {
    path: "/ComplexForm",
    name: "ComplexForm",
    meta: { title: "复杂表单", name: "ComplexForm" },
    component: () => import("../pages/ComplexForm.vue"),
  },
  {
    path: "/Homepage",
    children: [
      {
        path: "DataView",
        meta: { title: "数据视图", name: "PageDataView" },
        component: () => import("../pages/homepage/PageDataView.vue"),
      },
    ],
  },
  {
    path: "/Sale",
    children: [
      {
        path: "OrderDetails",
        meta: { title: "订单明细", name: "PageOrderDetails" },
        component: () => import("../pages/sale/PageOrderDetails.vue"),
      },
    ],
  },
  {
    path: "/Produce",
    children: [
      {
        path: "ProductionSchedulingPlan",
        meta: { title: "排产申请", name: "PageProductionSchedulingPlan" },
        component: () =>
          import("../pages/produce/PageProductionSchedulingPlan.vue"),
      },
      {
        path: "MaterialRequirements",
        meta: { title: "物料需求", name: "PageMaterialRequirements" },
        component: () =>
          import("../pages/produce/PageMaterialRequirements.vue"),
      },
      {
        path: "PurchasingRequisition",
        meta: { title: "采购申请", name: "PagePurchasingRequisition" },
        component: () =>
          import("../pages/produce/PagePurchasingRequisition.vue"),
      },
      {
        path: "ProductionTasks",
        meta: { title: "生产任务", name: "PageProductionTasks" },
        component: () => import("../pages/produce/PageProductionTasks.vue"),
      },
    ],
  },
  {
    path: "/PurchaseSalesInventory",
    children: [
      {
        path: "PurchaseOrder",
        meta: { title: "采购订单", name: "PagePurchaseOrder" },
        component: () =>
          import("../pages/purchaseSalesInventory/PagePurchaseOrder.vue"),
      },
      {
        path: "PurchaseReceipt",
        meta: { title: "采购收货", name: "PagePurchaseReceipt" },
        component: () =>
          import("../pages/purchaseSalesInventory/PagePurchaseReceipt.vue"),
      },
      {
        path: "QuotationManagement",
        meta: { title: "报价管理", name: "PageQuotationManagement" },
        component: () =>
          import("../pages/purchaseSalesInventory/PageQuotationManagement.vue"),
      },
      {
        path: "WarehouseManagement",
        meta: { title: "入库管理", name: "PageWarehouseManagement" },
        component: () =>
          import("../pages/purchaseSalesInventory/PageWarehouseManagement.vue"),
      },
      {
        path: "OutboundManagement",
        meta: { title: "出库管理", name: "PageOutboundManagement" },
        component: () =>
          import("../pages/purchaseSalesInventory/PageOutboundManagement.vue"),
      },
      {
        path: "InventoryCount",
        meta: { title: "库存盘点", name: "PageInventoryCount" },
        component: () =>
          import("../pages/purchaseSalesInventory/PageInventoryCount.vue"),
      },
      {
        path: "InventoryManagement",
        meta: { title: "库存管理", name: "PageInventoryManagement" },
        component: () =>
          import("../pages/purchaseSalesInventory/PageInventoryManagement.vue"),
      },
      {
        path: "MaterialOccupationManagement",
        meta: { title: "占料管理", name: "PageMaterialOccupationManagement" },
        component: () =>
          import(
            "../pages/purchaseSalesInventory/PageMaterialOccupationManagement.vue"
          ),
      },
      {
        path: "ShipmentRequest",
        meta: { title: "发货申请", name: "PageShipmentRequest" },
        component: () =>
          import("../pages/purchaseSalesInventory/PageShipmentRequest.vue"),
      },
      {
        path: "DeliveryManagement",
        meta: { title: "发货管理", name: "PageDeliveryManagement" },
        component: () =>
          import("../pages/purchaseSalesInventory/PageDeliveryManagement.vue"),
      },
    ],
  },
  {
    path: "/Finance",
    children: [
      {
        path: "CollectionBill",
        meta: { title: "收款账单", name: "PageCollectionBill" },
        component: () => import("../pages/finance/PageCollectionBill.vue"),
      },
      {
        path: "PaymentBill",
        meta: { title: "付款账单", name: "PagePaymentBill" },
        component: () => import("../pages/finance/PagePaymentBill.vue"),
      },
      {
        path: "ReimbursementAndPayment",
        meta: { title: "报销付款", name: "PageReimbursementAndPayment" },
        component: () =>
          import("../pages/finance/PageReimbursementAndPayment.vue"),
      },
      {
        path: "ReimburseManagement",
        meta: { title: "报销管理", name: "PageReimburseManagement" },
        component: () =>
          import("../pages/finance/PageReimburseManagement.vue"),
      },
      {
        path: "CostRecord",
        meta: { title: "费用记录", name: "PageCostRecord" },
        component: () => import("../pages/finance/PageCostRecord.vue"),
      },
    ],
  },
  {
    path: "/Information",
    children: [
      {
        path: "MaterialClassification",
        meta: { title: "物料分类", name: "PageMaterialClassification" },
        component: () =>
          import("../pages/information/PageMaterialClassification.vue"),
      },
      {
        path: "MaterialManagement",
        meta: { title: "物料管理", name: "PageMaterialManagement" },
        component: () =>
          import("../pages/information/PageMaterialManagement.vue"),
      },
      {
        path: "BOMmanagement",
        meta: { title: "BOM管理", name: "PageBOMmanagement" },
        component: () => import("../pages/information/PageBOMmanagement.vue"),
      },
      {
        path: "BOMversion",
        meta: { title: "BOM版本", name: "PageBOMversion" },
        component: () => import("../pages/information/PageBOMversion.vue"),
      },
      {
        path: "CustomerManagement",
        meta: { title: "客户管理", name: "PageCustomerManagement" },
        component: () =>
          import("../pages/information/PageCustomerManagement.vue"),
      },
      {
        path: "SupplierManagement",
        meta: { title: "供应商管理", name: "PageSupplierManagement" },
        component: () =>
          import("../pages/information/PageSupplierManagement.vue"),
      },
      {
        path: "CollectionConfiguration",
        meta: { title: "收款配置", name: "PageCollectionConfiguration" },
        component: () =>
          import("../pages/information/PageCollectionConfiguration.vue"),
      },
      {
        path: "DiscountedConfiguration",
        meta: { title: "优惠配置", name: "PageDiscountedConfiguration" },
        component: () =>
          import("../pages/information/PageDiscountedConfiguration.vue"),
      },
    ],
  },
  {
    path: "/System",
    children: [
      {
        path: "DepartmentManagement",
        meta: { title: "部门管理", name: "PageDepartmentManagement" },
        component: () => import("../pages/system/PageDepartmentManagement.vue"),
      },
      {
        path: "RoleManagement",
        meta: { title: "角色管理", name: "PageRoleManagement" },
        component: () => import("../pages/system/PageRoleManagement.vue"),
      },
      {
        path: "UserManagement",
        meta: { title: "用户管理", name: "PageUserManagement" },
        component: () => import("../pages/system/PageUserManagement.vue"),
      },
      {
        path: "SystemManagement",
        meta: { title: "系统管理", name: "PageSystemManagement" },
        component: () => import("../pages/system/PageSystemManagement.vue"),
      },
    ],
  },

  // {
  //   path: "",
  //   meta: { title: "", name: "Page" },
  //   component: () => import("../pages/purchaseSalesInventory/Page.vue"),
  // },
];

export default routes;
