<script setup>
import { ref, shallowReactive, useSlots, watch, nextTick, computed } from "vue";
import $$ from "@/tools";
import _ from "lodash";

/**
 * @typedef {Object} props
 *
 * @property {Array<columnConfig>} columnConfig - 列配置数组
 * @typedef {Object} columnConfig
 * @property {string} label - 标题
 * @property {string} key - 键
 * @property {string} width - 宽度
 * @property {string} tooltip - 过长被隐藏时显示 tooltip
 * @property {string} fixed - 定位在左边或者右边
 */
const props = defineProps({
  // 自动分页
  autoPagination: {
    type: Boolean,
    default: false,
  },
  // 树形结构children key名称
  childrenKey: String,
  // 表格列配置
  columnConfig: Array,
  // 表格数据
  tableData: Array,
  // 不需要序号列
  dontNeedIndex: Boolean,
  // el-table配置
  config: Object,
  // el-pagination配置
  paginationConfig: Object,
  // 使用分页器
  usePagination: {
    type: Boolean,
    default: true,
  },
  // 行唯一标识
  rowId: {
    type: String,
    default: "id",
  },
  loading: {
    type: Boolean,
    default: true,
  },
  rowClick: Function,
  rowDbClick: Function,
  expandChange: Function,
  disableSelect: {
    type: Array,
    default: [],
  },
});
const tableRef = ref(null);
const emits = defineEmits(["search"]);
const tableSelect = defineModel("tableSelect");
const slots = useSlots();
const defaultConfig = { pageSizes: [15, 30, 60, 120] };
const state = shallowReactive({
  tableData: [], // []
  currentPage: 1,
  pageSize: null, // 15
  pageSizes: null, // []
  total: 0,
});
if (props.tableSelect === '' || props.tableSelect === true) {
  tableSelect.value = []
}
const searchTableData = _.debounce(() => {
  emits("search");
}, 100);
const cutTableData = _.debounce(() => {
  const s = (state.currentPage - 1) * state.pageSize;
  state.tableData = props.tableData
    ? props.tableData.slice(s, s + state.pageSize)
    : [];
}, 100);

state.pageSizes =
  props.paginationConfig?.["page-sizes"] || defaultConfig.pageSizes;
state.pageSize = props.paginationConfig?.["page-size"] || state.pageSizes[0];

if (props.usePagination) {
  watch(
    () => state.pageSizes,
    (n) => {
      state.pageSize = state.pageSizes[0];
    }
  );
  watch(
    () => state.pageSize,
    (n) => {
      state.currentPage = 1;
      search();
    }
  );
  watch(
    () => state.currentPage,
    (n) => {
      if (currentChangeRushSearch) search();
      else currentChangeRushSearch = true
    }
  );
}

watch(
  () => props.tableData,
  (n) => {
    if (props.autoPagination) search();
  },
  { immediate: true, deep: true }
);

// methods
let currentChangeRushSearch = true
function initCurrentPage(search = false) {
  state.currentPage = 1;
  initSelect()
  currentChangeRushSearch = search
  setTimeout(() => currentChangeRushSearch = true, 50)
}
function initSelect(rows = []) {
  if (!tableSelect.value) return
  selectItem = rows.reduce((pre, item) => {
    pre[item[props.rowId]] = item
    return pre
  }, {})
  tableSelect.value = Object.keys(selectItem)
}
function search() {
  if (props.autoPagination) {
    cutTableData();
  } else {
    searchTableData();
  }
}

function renderSelect() {
  nextTick(() => {
    const thisPageSelect = (props.autoPagination ? state.tableData : props.tableData).filter(item => selectItem[item[props.rowId]])
    thisPageSelect.forEach((item) => {
      tableRef.value.toggleRowSelection(item, true)
    })
  })
}
function setSelect(rows) {
  initSelect(rows)
  renderSelect()
}

let selectItem = {}
function select(selectList, selectOne) {
  const ids = selectList.map(item => item[props.rowId])
  if (selectOne) {
    // 添加或者移除(单选)
    if (ids.includes(selectOne[props.rowId])) {
      // 添加
      selectItem[selectOne[props.rowId]] = selectOne
    } else {
      // 移除
      delete selectItem[selectOne[props.rowId]]
    }
  } else {
    // 添加或者移除(全选)
    if (ids.length) {
      // 添加
      selectList.forEach(item => {
        selectItem[item[props.rowId]] = item
      });
    } else {
      // 移除
      (props.autoPagination ? state.tableData : props.tableData).forEach(item => {
        delete selectItem[item[props.rowId]]
      });
    }
  }

  tableSelect.value = Object.keys(selectItem)
}

const currentPageSelectRow = computed(() =>
  tableSelect.value
    ? (props.autoPagination
      ? state.tableData
      : props.tableData).filter(
        item => selectItem[item[props.rowId]]
      )
    : []
)

// methods

defineExpose({
  initCurrentPage,
  getCurrentPage() {
    return state.currentPage;
  },
  getPageSize() {
    return state.pageSize;
  },
  setTotal(val) {
    state.total = val;
  },
  setCurrentRow(row) {
    tableRef.value.setCurrentRow(row);
  },
  initSelect,
  renderSelect,
  setSelect,
  getSelectRowId(all = true) {
    if (all) return Object.keys(selectItem)
  },
  getSelectRowData(all = true) {
    if (all) return Object.values(selectItem)
  },
  expandRow() {
    tableRef.value.toggleRowExpansion(...arguments)
  }
});
</script>

<template>
  <div class="WandH_100p flex _fdC">
    <el-table ref="tableRef"
      class="f1 theTableBox"
      border
      style="width: 100%"
      scrollbar-always-on
      :row-key="props.rowId"
      :tree-props="props.childrenKey && { children: props.childrenKey }"
      :data="props.autoPagination ? state.tableData : props.tableData"
      v-loading="loading"
      highlight-current-row
      @row-click="props.rowClick"
      @row-dblclick="props.rowDbClick"
      @select="select"
      @select-all="select"
      @expand-change="props.expandChange"
      v-bind="{ ...$$.constant.loadingBind, ...props.config }">
      <el-table-column v-if="tableSelect"
        type="selection"
        :selectable="row => !props.disableSelect.includes(row[props.rowId])"
        width="44"
        fixed="left" />
      <el-table-column v-if="!props.childrenKey && !props.dontNeedIndex"
        type="index"
        :index="(idx) => (state.currentPage - 1) * state.pageSize + idx + 1"
        label="序号"
        width="58"
        fixed="left" />
      <el-table-column v-for="column in props.columnConfig"
        :key="column.key"
        :prop="column.key"
        :label="column.label"
        :min-width="column.width || ''"
        :show-overflow-tooltip="column.tooltip"
        :fixed="column.fixed || false"
        v-bind="column.config">
        <!-- <component :is=""></component> -->
        <template v-if="slots[column.key + '_header']"
          #header="scope">
          <!-- scope: { row: any, column: any, $index: number } -->
          <slot :name="column.key + '_header'"
            v-bind="scope" />
        </template>
        <template v-if="slots[column.key]"
          #default="scope">
          <!-- scope: { column: any, $index: number } -->
          <slot :name="column.key"
            v-bind="scope" />
        </template>
      </el-table-column>
    </el-table>
    <el-form v-if="props.usePagination">
      <el-pagination class="mt_L"
        background
        v-model:current-page="state.currentPage"
        v-model:page-size="state.pageSize"
        :page-sizes="state.pageSizes"
        layout="total, slot, ->, sizes, prev, pager, next"
        :total="props.autoPagination ? props.tableData.length : state.total"
        v-bind="props.paginationConfig">
        <template v-if="tableSelect"
          #default>
          <span class=" ml_M"
            style="color: var(--el-text-color-regular)">
            当前页面勾选:
            <span style="color: var(--self-mainColor);">{{ currentPageSelectRow.length }}</span>
            条，总共勾选:
            <span style="color: var(--self-mainColor);">{{ tableSelect.length }}</span>
            条
          </span>
        </template>
      </el-pagination>
    </el-form>
  </div>
</template>

<style lang="scss">
$borderColor: #bfbfbf;

#app .theTableBox {

  .el-table__inner-wrapper::after {
    background-color: $borderColor;
  }

  thead>tr>th.el-table__cell {
    border-color: $borderColor;
    background-color: #f5f5f5;
    font-size: 1.1em;

    &:first-child {
      border-left: 2px solid $borderColor;
    }

    &:last-child {
      border-right: 2px solid $borderColor;
    }
  }
}
</style>
