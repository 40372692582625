const red = "#f5222d",
  blue = "#1677ff",
  green = "#52c41a",
  orange = "#faad14";

export default {
  disabled: [
    { name: "启用中", id: false },
    { name: "禁用中", id: true },
  ],
  enable: [
    { name: "启用中", id: true },
    { name: "禁用中", id: false },
  ],
  enable_: [
    { name: "启用中", id: true, color: green },
    { name: "弃用中", id: false, color: red },
  ],
  enable__: [
    { name: "启用中", id: 1, color: green },
    { name: "弃用中", id: 0, color: red },
  ],

  paymentStatus: [
    { name: "全部", id: 0 },
    { name: "待审批", id: 1, color: blue },
    { name: "审批中", id: 2, color: orange },
    { name: "待支付", id: 3, color: red },
    { name: "已支付", id: 4, color: green },
  ],
  collectionStatus: [
    { name: "全部", id: 0 },
    { name: "未收款", id: 1, color: red },
    { name: "待核销", id: 2, color: blue },
    { name: "收款完成", id: 3, color: green },
  ],
  paymentBillType: [
    { name: "全部", id: 0 },
    { name: "付款单", id: 1 },
    { name: "预付单", id: 2 },
  ],
  collectionBillType: [
    { name: "全部", id: 0 },
    { name: "收款单", id: 1 },
    { name: "预收单", id: 2 },
  ],
  billStatus: [
    { name: "全部", id: 0 },
    { name: "可编辑", id: 1, color: blue },
    { name: "生效", id: 2, color: green },
    { name: "废弃", id: 3, color: red },
  ],
  collectionDetailStatus: [
    { name: "可编辑", id: 1, color: blue },
    { name: "生效", id: 2, color: green },
    { name: "废弃", id: 3, color: red },
  ],
  BOMStatus: [
    { name: "待创建", id: 0, color: red },
    { name: "草稿", id: 1, color: orange },
    { name: "未生效", id: 2, color: blue },
    { name: "生效", id: 3, color: green },
  ],
  purchaseOrderStatus: [
    { name: "所有", id: 0 },
    { name: "编辑中", id: 1, color: blue },
    { name: "审批中", id: 2, color: orange },
    { name: "收货中", id: 3, color: green },
    { name: "已结束", id: 4 },
    { name: "已废弃", id: 5, color: red },
  ],
  storageTypeSearch: [
    { name: "所有", id: 0 },
    { name: "采购入库", id: 1 },
    { name: "生产入库", id: 2 },
    { name: "生产退料入库", id: 3 },
    { name: "退货.成品入库", id: 4 },
    { name: "退货.拆解入库", id: 5 },
    { name: "调拨入库", id: 6 },
    { name: "盘亏入库", id: 7 },
    { name: "盘盈入库", id: 8 },
  ],
  outboundTypeSearch: [
    { name: "所有", id: 0 },
    { name: "销售出库", id: 1 },
    { name: "生产领料", id: 2 },
    { name: "退货出库", id: 3 },
    { name: "调拨出库", id: 4 },
  ],
  warehouseType: [
    { name: "良品仓", id: 1 },
    { name: "不良品仓", id: 2 },
    { name: "废品仓", id: 3 },
  ],
  status: [
    { name: "所有", id: 0 },
    { name: "待生效", id: 1, color: blue },
    { name: "生效", id: 2, color: green },
    { name: "弃用", id: 3, color: red },
  ],
  storageTypeForm: [
    { name: "采购入库", id: 0 },
    { name: "生产入库", id: 1 },
    { name: "委外生产入库", id: 2 },
    { name: "生产退料入库", id: 3 },
    { name: "退货成品入库", id: 4 },
    { name: "退货拆解入库", id: 5 },
  ],
  paymentType: [
    { name: "采购入库", id: 1 },
    { name: "采购退货", id: 2 },
    { name: "委外加工入库", id: 3 },
    { name: "委外加工退货", id: 4 },
    { name: "物流付款", id: 5 },
    { name: "其他付款", id: 6 },
    { name: "预付款", id: 7 },
  ],
  collectionType: [
    { name: "销售订单", id: 1 },
    { name: "预收单", id: 2 },
  ],
  detailFormType: [
    { name: "收款明细", id: 13 },
    { name: "预收单", id: 14 },
    { name: "销售单", id: 15 },
  ],
  expenseType: [
    { name: "运输费", id: 2 },
    { name: "包装费", id: 3 },
    { name: "加工费", id: 4 },
    { name: "税金", id: 9 },
    { name: "其他费用", id: 5 },
  ],
  expenseType_: [
    { name: "订单增值", id: 6 },
    { name: "订单减免", id: 7 },
  ],
  saleOrderDetailsType: [
    { name: "全部", id: 0 },
    { name: "销售单", id: 1 },
    { name: "备货单", id: 2 },
    { name: "售后单", id: 3 },
  ],
  saleOrderDetailsStatus: [
    { name: "全部", id: 0 },
    { name: "待确定", id: 1, color: blue },
    { name: "生效中", id: 2, color: green },
    { name: "关闭", id: 4, color: red },
  ],
  taskStatus: [
    { name: "未处理", id: 1, color: orange },
    { name: "处理中", id: 2, color: blue },
    { name: "已完成", id: 3, color: green },
    { name: "失败", id: 4, color: red },
    { name: "处理超时", id: 5, color: red },
  ],
  reimbursementAndPayment: [
    { name: "全部", id: 0 },
    { name: "未支付", id: 1, color: red },
    { name: "已支付", id: 2, color: green },
  ],
  costType: [
    { name: "生产人员工资", id: 1 },
    { name: "生产人员社保", id: 2 },
    { name: "销售员社保", id: 3 },
    { name: "销售员工资", id: 4 },
    { name: "销售员福利", id: 5 },
    { name: "销售员奖金", id: 6 },
  ],
  inventoryCountStatus: [
    { name: "全部", id: 0 },
    { name: "可应用", id: 1, color: blue },
    { name: "已过期", id: 2, color: red },
    { name: "已应用", id: 3, color: green },
  ],

  materialRequirementsStatus: [
    { name: "全部", id: 0 },
    { name: "已生成", id: 2, color: green },
    { name: "未生成", id: 1, color: red },
  ],
  procurementRequestStatus: [
    { name: "全部", id: 0 },
    { name: "待采购", id: 1, color: blue },
    { name: "已生成采购单", id: 2, color: green },
    { name: "废弃", id: 3, color: red },
  ],
  purchaseAndReceiveGoodsStatus: [
    { name: "未结案", id: 1, color: red },
    { name: "已结案", id: 2, color: green },
  ],
  productionSchedulingPlanStatus: [
    { name: "全部", id: 0 },
    { name: "待出货", id: 1, color: blue },
    { name: "出货中", id: 2, color: orange },
    { name: "已完成", id: 3, color: green },
    { name: "退回", id: 4, color: red },
  ],
  shipmentRequestStatus: [
    { name: "全部", id: 0 },
    { name: "待发货", id: 1, color: blue },
    { name: "发货中", id: 2, color: orange },
    { name: "已结束", id: 3, color: green },
    { name: "已弃用", id: 4, color: red },
  ],
  deliveryManagementStatus: [
    { name: "全部", id: 0 },
    { name: "草稿", id: 1, color: orange },
    { name: "已生效", id: 2, color: green },
    { name: "已弃用", id: 3, color: red },
  ],
  delivery: [
    { name: "快递", id: 1 },
    { name: "物料", id: 2 },
    { name: "网约车", id: 3 },
    { name: "自配送", id: 4 },
    { name: "自提", id: 5 },
  ],
  deliveryPaymentStatus: [
    { name: "待申请", id: 0, color: blue },
    { name: "待审批", id: 1, color: blue },
    { name: "审批中", id: 2, color: orange },
    { name: "待付款", id: 3, color: red },
    { name: "已付款", id: 4, color: green },
  ],
  receiptStatus: [
    { name: "全部", id: 0 },
    { name: "待收款", id: 1, color: blue },
    { name: "已完成", id: 2, color: green },
  ],
  deliveryStatus: [
    { name: "全部", id: 0 },
    { name: "待发货", id: 1, color: blue },
    { name: "已完成", id: 2, color: green },
  ],
  materialOccupationStatus: [
    { name: "生效", id: 0, color: blue },
    { name: "释放", id: 1, color: red },
  ],
  materialOccupationType: [{ name: "销售单", id: 15 }],
};
