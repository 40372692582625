<script setup>
import { Plus } from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import { computed, nextTick, reactive, ref, toRaw, useSlots, watch } from "vue";

const formRef = ref(null);
const show = defineModel("show", { type: Boolean, default: false });
const justCheck = defineModel("justCheck", { type: Boolean, default: false });
const editData = defineModel("editData", { type: Object });
const slots = useSlots();
/**
 * @typedef {Object} props
 *
 * @property {Array<FieldConfig>} fieldConfig - 列配置数组
 * @typedef {Object} FieldConfig
 * @property {string} label - 标题
 * @property {string} key - res的key
 * @property {array} [extraKey] - 字段需要额外携带的数据
 * @property {string} [valueKey] - 传这个的话就是值取这个指定的，但是绑定进res的key取上面那个key
 * @property {string|'textarea'} [type] - 输入框的类型
 * @property {boolean} [wide] - true: 独占一行; 数字: 占多少列
 * @property {array} [rule] - 输入框的规则
 * @property {object} [config] - 覆盖原先组件的配置
 *
 * type == 'select' 才有
 * @property {function} select - 选择的时候触发的函数(item: typeof modelValue | any)
 */
const props = defineProps({
  btnConfig: Object,
  title: String,
  fieldConfig: Object,
  data: { type: Object, default: {} },
  addBtnShow: { type: Boolean, default: true },
  width: String,
  loading: {
    type: Boolean,
    default: false,
  },
  config: Object,
  dialogConfig: Object,
  // 字段有几列
  column: {
    type: [Number, String],
    default: 2,
  },
  saveText: String,
});
const isCol = computed(() =>
  ["left", "right"].includes(props.config?.["label-position"])
);
const relation = {
  add: { title: "添加", emit: "add" },
  edit: { title: "编辑", emit: "edit" },
};
const emits = defineEmits(["add", "edit"]);

const state = reactive({
  type: "",
});
const res = ref({})

watch(
  () => editData.value,
  (n, o) => {
    show.value = Boolean(n);
    if (n) {
      state.type = "edit";
      if (o) renderData();
    }
  },
  { immediate: true }
);
watch(
  () => show.value,
  (n) => {
    if (n) {
      if (state.type == "edit") {
        if (editData.value?.__notEdit__) {
          delete editData.value.__notEdit__;
          state.type = "add";
        }
        renderData();
      } else {
        state.type = "add";
        nextTick(() => {
          res.value = {};
          formRef.value.resetFields();
        });
      }
    } else {
      setTimeout(() => {
        res.value = {};
        formRef.value?.resetFields();
        state.type = "";
        justCheck.value = false;
        editData.value = null;
      }, 200)
    }
  }
);
const renderData = () => {
  if (props.fieldConfig)
    props.fieldConfig.forEach((cf) => {
      const data = editData.value[cf.valueKey || cf.key];
      if (["select", "treeSelect"].includes(cf.type) && !data) return;
      res.value[cf.key] = data;
      if (cf.extraKey)
        cf.extraKey.forEach((key) => {
          res.value[key] = editData.value[key];
        });
    });
};

// methods
function submit() {
  formRef.value
    .validate()
    .then((validate) => {
      if (validate) {
        emits(relation[state.type].emit, getRes(), getResSource());
      }
    })
    .catch((err) => {
      console.log("err", err);
      ElMessage.warning("请检查表单");
    });
}
function showWithAdd() {
  state.type = "add";
  justCheck.value = false;
  show.value = true;
}
function showWithEdit(data) {
  justCheck.value = false;
  editData.value = data;
}
function getRes() {
  return toRaw(res.value)
}
function getResSource() {
  return (res.value)
}
// methods
defineExpose({
  showWithAdd,
  showWithEdit,
  getRes,
  getResSource,
  validate(field) {
    if (formRef.value)
      if (field) return formRef.value.validateField(field)
      else return formRef.value.validate()
  }
})
</script>

<template>
  <el-button v-if="props.addBtnShow"
    type="primary"
    :icon="props.icon || Plus"
    v-bind="props.btnConfig"
    @click="showWithAdd">
    {{ props.btnConfig?.title || `添加${props.title}` }}
  </el-button>

  <el-dialog v-model="show"
    :close-on-click-modal="!props.loading"
    :close-on-press-escape="!props.loading"
    :show-close="!props.loading"
    :width="props.width || (isCol ? '20%' : '36%')"
    :title="(props.addBtnShow ? relation[state.type]?.title : '') + props.title"
    align-center
    v-bind="props.dialogConfig">
    <el-scrollbar max-height="66vh"
      v-loading="props.loading"
      element-loading-text="加载中...">
      <slot />
      <slot name="before_table"
        v-bind="{ data: res }"></slot>
      <el-form :disabled="justCheck"
        :validate-on-rule-change="false"
        :model="res"
        label-position="top"
        scroll-to-error
        v-bind="props.config"
        ref="formRef">
        <div class="cfBox">
          <div class="cfItemBox"
            v-for="cf in props.fieldConfig"
            :key="cf.key"
            :style="{
              width: cf.wide === true || props.column == cf.wide
                ? '100%'
                : `calc(${100 / (props.column / (cf.wide || 1))}% - ${(12 * (props.column - (cf.wide || 1))) / props.column}px)`
            }">
            <el-form-item v-if="cf.key"
              :rules="cf.rule"
              :prop="cf.key"
              :label="cf.label"
              v-bind="cf.itemConfig">
              <template v-if="slots[cf.key]">
                <slot :name="cf.key"
                  v-bind="{
                    data: res,
                  }" />
              </template>
              <div v-else-if="cf.type == 'treeSelect'"
                :style="{ width: isCol ? '192px' : '100%' }">
                <el-tree-select :placeholder="`请选择${cf.label}`"
                  :node-key="cf.idKey || 'id'"
                  :props="{
                    label: cf.labelKey || 'name',
                    children: cf.childrenKey || 'childrenList',
                  }"
                  v-model="res[cf.key]"
                  :render-after-expand="false"
                  :data="props.data[cf.key]"
                  clearable
                  check-strictly
                  default-expand-all
                  filterable
                  @node-click="cf.nodeClick"
                  v-bind="cf.config" />
              </div>
              <el-select v-else-if="cf.type == 'select'"
                :style="[isCol && 'width: 192px']"
                v-model="res[cf.key]"
                clearable
                filterable
                :placeholder="`请选择${cf.label}`"
                @change="(val) => cf.select && cf.select(val, res)"
                v-bind="cf.config">
                <el-option v-for="item in props.data[cf.key]"
                  :label="item[cf.labelKey || 'name']"
                  :key="item[cf.idKey || 'id']"
                  :value="item[cf.idKey || 'id']" />
              </el-select>
              <el-input v-else-if="cf.type == 'textarea'"
                v-model="res[cf.key]"
                clearable
                show-word-limit
                type="textarea"
                :autosize="{ minRows: 3 /* , maxRows: 4 */ }"
                :placeholder="'请输入' + cf.label"
                v-bind="cf.config" />
              <el-date-picker v-else-if="cf.type == 'datePicker'"
                :style="{ 'max-width': cf.config ? '252px' : (isCol ? '192px' : '100%') }"
                v-model="res[cf.key]"
                value-format="YYYY-MM-DD"
                range-separator="至"
                start-placeholder="起始时间"
                end-placeholder="结束时间"
                :placeholder="'请选择' + cf.label"
                v-bind="cf.config" />
              <span v-else-if="cf.type == 'text'">{{ res[cf.key] }}</span>
              <el-input v-else
                :style="[isCol && 'width: 192px']"
                v-model="res[cf.key]"
                :placeholder="'请输入' + cf.label"
                clearable
                v-bind="cf.config" />
            </el-form-item>
          </div>
        </div>
      </el-form>
      <slot name="after_table"
        v-bind="{ data: res }"></slot>
    </el-scrollbar>

    <template #footer>
      <slot name="footer">
        <div>
          <el-button @click="show = false"
            v-show="!props.loading">
            {{ justCheck ? "关闭" : "取消" }}
          </el-button>

          <slot name="footerMiddle"></slot>

          <el-button v-if="!justCheck"
            @click="submit"
            type="primary"
            :loading="props.loading">
            {{ props.saveText || '提交' }}
          </el-button>
        </div>
      </slot>
    </template>
  </el-dialog>
</template>

<style scoped lang="scss">
.cfBox {
  display: flex;
  flex-wrap: wrap;
  column-gap: 12px;

  .cfItemBox {
    :deep(.el-form-item--label-top .el-form-item__label) {
      margin-bottom: 0;
    }
  }
}
</style>
